<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <h3>Dashboard</h3>
      <v-row class="text--black">
        <v-col cols="12">
          <span>Project Details</span>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-primary"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  Total Projects
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  4
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-success"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  Completed Projects
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  1
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-progress"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  On Progress Projects
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  3
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-danger"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  Overdue Projects
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  0
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-success"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  Over 50% Projects
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  1
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-4"></v-divider>
      <v-row class="text--black">
        <v-col cols="12">
          <div style="display: flex; justify-content: space-between;">
            <span>Finance Details</span>
            <v-btn small color="primary" to="/projects/general-report">
              <v-icon>mdi-poll</v-icon>
              <span class="pl-1">Detailed Financial Report</span>
            </v-btn>
          </div>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-primary"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  Total Allocated
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  48,000,000,000/=
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-progress"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  Disbursed Amount
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  8,000,000,000/=
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-danger"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  Unpaid Certificates
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  2,000,000,000/=
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card
            link
            to=""
            class="mx-auto bg-gradient-success"
            max-width="100%"
            elevation="1"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4 ylw">
                  Remaining Amount
                </div>
                <v-list-item-title
                  style="font-weight: bold"
                  class="text-h5 mb-1"
                >
                  38,000,000,000/=
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </sequential-entrance>
</template>

<script>
export default {
  name: "DashBoard",
  data: () => ({
    hovered: true,
    overlay: false,
    componentDialog: false,
    detail: "/components/details/",
    sample: 1,
    component_list: [
      { name: "Digital Ecosystem", id: 1 },
      { name: "Digital Connectivity", id: 2 },
      { name: "Digital Platforms and Services", id: 3 },
    ],
  }),
};
</script>


<style scoped>
.dashboard-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bg-gradient-warning {
  background: rgb(30,58,138);
  background: linear-gradient(90deg, rgba(30,58,138,0.3) 0%, rgba(232,172,18,0.4) 100%);
}

.bg-gradient-primary {
  background: rgb(30,58,138);
  background: linear-gradient(90deg, rgba(30,58,138,0.3) 0%, rgba(30,58,138,0.5) 100%);
}

.bg-gradient-success {
  background: rgb(30,58,138);
  background: linear-gradient(90deg, rgba(13, 148, 137,0.3) 0%, rgba(13, 148, 137,0.5) 100%);
}

.bg-gradient-progress {
  background: rgb(245, 159, 11);
  background: linear-gradient(90deg, rgba(245, 159, 11,0.3) 0%, rgba(245, 159, 11,0.5) 100%);
}

.bg-gradient-danger {
  background: rgb(185, 28, 28);
  background: linear-gradient(90deg, rgba(185, 28, 28,0.3) 0%, rgba(185, 28, 28,0.5) 100%);
}

</style>



